import { useState } from 'react';
import { MailOutlined, SettingOutlined,AppstoreOutlined,HomeOutlined,PictureOutlined,AimOutlined,MergeCellsOutlined,ReadOutlined,UsergroupAddOutlined,PullRequestOutlined,FolderOutlined,FormOutlined,ContainerOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Button,Menu } from 'antd';
import Imalogo from './Imalogo';


function getItem(label, key, icon, children, type,link) {
  return {
    key,
    icon,
    children,
    label,
    type,
    link,
  };
}

const items = [
  getItem(<Imalogo />, '','',null,null,),
  getItem('Home', '', <HomeOutlined />,null,null,'upsc-admission'),
  getItem('About', 'About', <AimOutlined />),
  getItem('Upsc', 'sub1', <ReadOutlined />, [
    getItem('Admission', 'upsc-admission'),
    getItem('Course Calendar', 'upsc-courseCalender'),
    getItem('Terms and conditions', 'upsc-term-and-conditions'),
    getItem('About Exam', 'upsc-about-exam'),
  ]),
   getItem('Tnpsc', 'sub2', <ReadOutlined />, [
    getItem('Admission', 'tnpsc-admission'),
    getItem('Course Calendar', 'tnpsc-course-calender'),
    getItem('Terms and conditions', 'tnpsc-term-and-conditions'),
    getItem('About Exam', 'tnpsc-about-exam'),
  ]),

   getItem('Other Course', 'sub4', <MergeCellsOutlined />, [
    getItem('Police Exam', 'police-exam'),
    // getItem('SI Exam', 'SI-exam'),
    getItem('Banking Exam', 'bank-exam'),
    getItem('SSC Exam', 'SSC-exam'),
    getItem('RRB Exam', 'RRB-exam'),
  ]),
   getItem('Gallery', 'gallery',  <PictureOutlined />),
    getItem('Student Portal', 'sub5', <PullRequestOutlined />, [
    getItem('Class Schedule', 'class-schedule'),
    getItem('Materials', 'materials'),
  ]),
   getItem('Curent Affairs', 'current-affirs',  <FolderOutlined />),
   getItem('Contact', 'contact-us',  <UsergroupAddOutlined />),
   // getItem('Online Application', 'onlineAppicaation',  <ContainerOutlined />),
   getItem('Online Test', 'onlineTest',  <FormOutlined />),

];


// submenu keys of first level
const rootSubmenuKeys = ['sub1', 'sub2', 'sub4','sub5'];
const Sidemenu = () => {
  const [openKeys, setOpenKeys] = useState(['sub1']);
  const onOpenChange = (keys) => {

    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
      console.log(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
   const navigate = useNavigate();
  const onClick = (e) => {
    if(e.key == 'onlineAppicaation'){
      window.open('https://jsriasacademy.com/application.php');
    }else if(e.key == 'onlineTest'){
      window.open('https://jsriasacademy.com/jsronlineExam/');
    }else{
      // console.log(e.key);
    document.getElementById('menu__ulul').style = "display:none!important";
      navigate("/"+e.key);
    // history.push("/");
    }
  };

  return (
    <Menu
      mode="inline"
      onClick={onClick}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      style={{
        width: 256,
      }}
      items={items}
      className="menu__ulul"
      id="menu__ulul"
    />
  );
};
export default Sidemenu;