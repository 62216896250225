import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Component } from 'react';
import { Link,useNavigate } from "react-router-dom";
import Sidemenu from './Sidemenu';
import { Icon } from '@iconify/react';


import { FloatButton } from 'antd';
import { WhatsAppOutlined,MenuOutlined, InstagramOutlined,TwitterOutlined,YoutubeOutlined,FacebookOutlined,PlusOutlined } from '@ant-design/icons';
var isOpen = 0;

const resources = [
  {
    name: 'Admission',
    href: 'upsc-admission',
  },{
    name: 'Course Calendar',
    href: 'upsc-course-calendar',
   },
   //  ,{
   //   name: 'Free Structure',
   //   href: 'upsc/freeStructure',
   // },
   {
    name: 'Terms and conditions',
    href: 'upsc-term-and-conditions',
  },
  {
    name: 'About Exam',
    href: 'upsc-about-exam',
  },
]

const tnpscsources = [
  {
    name: 'Admission',
    href: 'tnpsc-admission',
  },{
    name: 'Course Calendar',
    href: 'tnpsc-course-calendar',
  },
  // {
  //   name: 'Free Structure',
  //   href: '',
  // },
  {
    name: 'Terms and conditions',
    href: 'tnpsc-term-and-conditions',
  },{
    name: 'About Exam',
    href: 'tnpsc-about-exam',
  },
];

const othercours = [
  {
    name: 'Police Exam',
    href: 'police-exam',
  },
  /*{
    name: 'SI Exam',
    href: 'SI-exam',
  },*/
  {
    name: 'Banking Exam',
    href: 'bank-exam',
  },{
    name: 'SSC Exam',
    href: 'SSC-exam',
  },
  {
    name: 'RRB Exam',
    href: 'RRB-exam',
  },
];

const studentportal = [
  {
    name: 'Class Schedule',
    href: 'class-schedule',
  },{
    name: 'Materials',
    href: 'materials',
  }
];




function OpenMenus() {
  const [isActive, setIsActive] = useState(false);

const handleClick = event => {


    if(document.querySelector('#menu__ulul').style.display == 'none' || document.querySelector('#menu__ulul').style.display == ''){
         document.getElementById('menu__ulul').style = "display:block!important";
    }else{
        document.getElementById('menu__ulul').style = "display:none!important";
    }

    setIsActive(!isActive); 


    // if(isActive==true){
    //     document.getElementById('menu__ulul').style = "display:none!important";
    // }else{
    //     document.getElementById('menu__ulul').style = "display:block!important";
    // }old
    
  };

  return (
    <div>
      <div onClick={handleClick}>
        <MenuOutlined />
      </div>
    </div>
  );
}




export default class Header extends Component {

handleClick() {
    // const mediaQuery = window.matchMedia('(max-width: 1198px)');
    // if (mediaQuery.matches) {

  // if(isOpen == 0){
  //     document.getElementById('menu__ulul').style = "display:block!important";
  //     isOpen = 1;
  //  }else{
  //     document.getElementById('menu__ulul').style = "display:none!important";
  //     isOpen = 0;
  //  }

  // }
}

brow_Component(param) { // param is the argument you passed to the function
    return function (e) { // e is the event object that returned
      window.open(param, "_blank");
    };
}

   render() {
 this.state = {isToggleOn: true};

  
    return (
       <div>

<header>
            <div id="header-sticky" className="header__area header__transparent header__padding">
               <div className="container-fluid">
                  <div className="row align-items-center">
                     <div className="col-md-2 logo__xs">
                        <div className="header__left d-flex">
                           <div className="logo">
<Link to="/"><img className="lazy" src={require('../jsrlogo.jpg')} data-src="image-to-lazy-load-1x.jpg" data-srcset="image-to-lazy-load-2x.jpg 2x, image-to-lazy-load-1x.jpg 1x" alt="I'm an image!" /></Link>
</div>
                           
                        </div>
                     </div>
                     <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-8 col-sm-8 col- logo__xs_list">
                        <div className="header__right d-flex align-items-center justify-content-evenly">
                           <div className="main-menu d-none d-xl-block" id="main-menu">
                              <nav id="mobile-menu">
                                 <ul>
                                 <div className="d-none d-xl-block set__height"></div>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/about">About</Link>
                                        
                                    </li>
                                    <li><Link>UPSC</Link>
                                    <div className="absolute z-10 left-1/2 transform -translate-x-1/2 px-2 w-screen max-w-xs sm:px-0">
                                          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                          <div className="relative grid bg-white px-3 py-2 submenu__li">
                                          {resources.map((item) => (
                                          <Link to={item.href}><span key={item.name} className="block rounded-md hover:gray-50">
                                          <p className="text-base font-medium text-gray-900">{item.name}</p>
                                          </span>
                                          </Link>
                                          ))}
                                          </div>
                                          </div>
                                          </div></li>
                                    <li><Link>TNPSC</Link>
                      <div className="absolute z-10 left-1/2 transform -translate-x-1/2 px-2 w-screen max-w-xs sm:px-0">
                           <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                           <div className="relative grid bg-white px-3 py-2 submenu__li">
                           {tnpscsources.map((item) => (
                           <Link to={item.href}><span key={item.name} className="block rounded-md hover:gray-50">
                           <p className="text-base font-medium text-gray-900">{item.name}</p>
                           </span>
                           </Link>
                           ))}
                           </div>
                           </div>
                           </div>
                                          </li>
                                    <li><Link>Other Course</Link>
                                       <div className="absolute z-10 left-1/2 transform -translate-x-1/2 px-2 w-screen max-w-xs sm:px-0">
                           <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                           <div className="relative grid bg-white px-3 py-2 submenu__li">
                           {othercours.map((item) => (
                           <Link to={item.href}><span key={item.name} className="block rounded-md hover:gray-50">
                           <p className="text-base font-medium text-gray-900">{item.name}</p>
                           </span>
                           </Link>
                           ))}
                           </div>
                           </div>
                           </div>
                            </li>
                                    <li><Link to="/gallery">Gallery</Link></li>

                                    <li><Link to="/class-schedule">Class Schedule</Link></li>

                                  {/*  <li><Link>student Portal</Link>
                                     <div className="absolute z-10 left-1/2 transform -translate-x-1/2 px-2 w-screen max-w-xs sm:px-0">
                           <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                           <div className="relative grid bg-white px-3 py-2 submenu__li">
                           {studentportal.map((item) => (
                           <Link to={item.href}><span key={item.name} className="block rounded-md hover:gray-50">
                           <p className="text-base font-medium text-gray-900">{item.name}</p>
                           </span>
                           </Link>
                           ))}
                           </div>
                           </div>
                           </div>
                                    </li>*/}
                                    <li><Link to="/current-affairs">Current Affairs</Link></li>

                              
                                    <li><Link to="/contact-us">Contact</Link></li>
                                    
                                 </ul>
                              </nav>
                           </div>

                           <div className="text-center header__btn ml-20 d-none d-sm-block hide_mb_posab__menu">
                           <a href="tel:7358001586">
                           <div className="text-base font-medium text-gray-900">Contact To JSR IAS Academy</div>
                           <div className="text-base font-sm text-gray-700">+91 7358 001 586</div>
                           </a>
                           </div>
                           {/*<div className="header__btn ml-20 d-none d-sm-block"><a href="ph:" target="_blank" className="btn-sm btn-custome-Hbtn">
                           contact to jsr academy <br /> 91 3987  9878 </a></div>*/}
                           {/*<div className="header__btn ml-20 d-none d-sm-block hide_mb_posab__menu"><a href="https://jsriasacademy.com/application.php" target="_blank" className="btn-sm btn-custome-Hbtn"> Online Application </a></div>
                           <div className="header__btn ml-20 d-none d-sm-block hide_mb_posab__menu"><a href="https://jsriasacademy.com/jsronlineExam/" target="_blank"  className="btn-sm btn-custome-Hbtn"> Online Test </a></div>*/}
                           <div className="sidebar__menu d-xl-none hide_mb_posab__try">
                              <OpenMenus />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>

         <Sidemenu />


    <FloatButton.Group
      shape="square"
      trigger="click"  
      style={{
        left: 10,
        width: 60,
        bottom: 10,
        padding: 10,
        background:'white',
      }}
     icon={<PlusOutlined />} 
    >
      <FloatButton onClick={this.brow_Component("https://t.me/JSRIASAcademy")} data-id="option1" icon={<Icon icon="ri:telegram-line" /> } />
      <FloatButton onClick={this.brow_Component('https://www.instagram.com/jsriasacademy/')} icon={<InstagramOutlined />} />      
      <FloatButton onClick={this.brow_Component('https://twitter.com/JSR_Academy?s=08')} icon={<TwitterOutlined />} />
      <FloatButton onClick={this.brow_Component('https://www.youtube.com/channel/UCK3y-sxYw9UzjHJm3l-2_rg/?guided_help_flow=5')} icon={<YoutubeOutlined />} />
      <FloatButton onClick={this.brow_Component('https://www.facebook.com/jsr.easttambaram.1')} icon={<FacebookOutlined />} />
    </FloatButton.Group>
        <FloatButton.BackTop />
    </div>   
    );
  }
}