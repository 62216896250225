import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, HashRouter, Route } from 'react-router-dom';
import GuidanceCategories from './components/Guidance&Categories';
import Header from './components/Header';
import Footer from './components/Footer';

import './index.css';
const Home1 = lazy(() => import('./Home1'));
const Home2 = lazy(() => import('./Home-2'));

const Mainpage = lazy(() => import('./Mainpage'));
const Aboutpage = lazy(() => import('./components/otherCources/Aboutpage'));
const Admission = lazy(() => import('./components/upsc/admission'));
const Calender = lazy(() => import('./components/upsc/calender'));
const UpscPolice = lazy(() => import('./components/upsc/policiesupsc'));
const AboutExam = lazy(() => import('./components/upsc/aboutExam'));
const TnpscAdmission = lazy(() => import('./components/tnpsc/admission'));
const TnpscCalender = lazy(() => import('./components/tnpsc/calender'));
const TnpscpoliceUPSc = lazy(() => import('./components/tnpsc/policiesTnpsc'));
const TnpscAboutExam = lazy(() => import('./components/tnpsc/aboutExam'));
const OthpolicyExam = lazy(() => import('./components/otherCources/policyExam'));
const SIExam = lazy(() => import('./components/otherCources/SIExam'));
const BankExam = lazy(() => import('./components/otherCources/BankExam'));
const SSCExam = lazy(() => import('./components/otherCources/SSCExam'));
const RRBExam = lazy(() => import('./components/otherCources/RRBExam'));
const CurentAffir = lazy(() => import('./components/otherCources/CurentAffir'));
const Group2list = lazy(() => import('./components/otherCources/Group2list'));
const Gallery = lazy(() => import('./components/otherCources/Gallery'));
const ClassSchedule = lazy(() => import('./components/studentPortal/ClassSchedule'));
const SchooleMaterials = lazy(() => import('./components/studentPortal/schooleMaterials'));
const Contactpage = lazy(() => import('./components/Contactpage'));
const Jsrbooks = lazy(() => import('./components/Jsrbooks'));
const FreeTest = lazy(() => import('./components/freetest/FreeTest'));

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HashRouter>
    <Header />
    <Routes>
      <Route path="/" element={<Suspense fallback={<div>Loading...</div>}><Mainpage /></Suspense>} />
      
      <Route path="/home-1" element={<Suspense fallback={<div>Loading...</div>}><Home1 /></Suspense>} />
      <Route path="/home-2" element={<Suspense fallback={<div>Loading...</div>}><Home2 /></Suspense>} />

      <Route path="/about" element={<Suspense fallback={<div>Loading...</div>}><Aboutpage /></Suspense>} />
      <Route path="/upsc-admission" element={<Suspense fallback={<div>Loading...</div>}><Admission /></Suspense>} />
      <Route path="/upsc-course-calendar" element={<Suspense fallback={<div>Loading...</div>}><Calender /></Suspense>} />
      <Route path="/upsc-term-and-conditions" element={<Suspense fallback={<div>Loading...</div>}><UpscPolice /></Suspense>} />
      <Route path="/upsc-about-exam" element={<Suspense fallback={<div>Loading...</div>}><AboutExam /></Suspense>} />

      <Route path="/tnpsc-admission" element={<Suspense fallback={<div>Loading...</div>}><TnpscAdmission /></Suspense>} />
      <Route path="/tnpsc-course-calendar" element={<Suspense fallback={<div>Loading...</div>}><TnpscCalender /></Suspense>} />
      <Route path="/tnpsc-term-and-conditions" element={<Suspense fallback={<div>Loading...</div>}><TnpscpoliceUPSc /></Suspense>} />
      
      <Route exact path="/tnpsc-about-exam/*" element={<Suspense fallback={<div>Loading...</div>}><TnpscAboutExam /></Suspense>} />
      <Route path="/police-exam" element={<Suspense fallback={<div>Loading...</div>}><OthpolicyExam /></Suspense>} />
      

      <Route path="/SI-exam" element={<Suspense fallback={<div>Loading...</div>}><SIExam /></Suspense>} />
      <Route exact path="/bank-exam/*" element={<Suspense fallback={<div>Loading...</div>}><BankExam /></Suspense>} />
      <Route path="/SSC-exam/" element={<Suspense fallback={<div>Loading...</div>}><SSCExam /></Suspense>} />
      <Route path="/RRB-exam/" element={<Suspense fallback={<div>Loading...</div>}><RRBExam /></Suspense>} />
      <Route path="/class-schedule/*" element={<Suspense fallback={<div>Loading...</div>}><ClassSchedule /></Suspense>} />
      <Route path="/current-affairs/" element={<Suspense fallback={<div>Loading...</div>}><CurentAffir /></Suspense>} />
      <Route path="/group-2-test/" element={<Suspense fallback={<div>Loading...</div>}><Group2list /></Suspense>} />
      <Route path="/gallery/" element={<Suspense fallback={<div>Loading...</div>}><Gallery /></Suspense>} />
      <Route path="/materials/*" element={<Suspense fallback={<div>Loading...</div>}><SchooleMaterials /></Suspense>} />
      <Route path="/contact-us/" element={<Suspense fallback={<div>Loading...</div>}><Contactpage /></Suspense>} />
      <Route path="/jsr/:category" element={<Suspense fallback={<div>Loading...</div>}><Jsrbooks /></Suspense>} />
      <Route path="/jsr_free_test/:his_id" element={<Suspense fallback={<div>Loading...</div>}><FreeTest /></Suspense>} />
      {/* ... (lazy import other components) */}
    </Routes>

    <div className="pt-20 pb-60">
 <div className="">

        <div className="flex flex-wrap items-center justify-between py-8 px-6 xs:px-10 md:px-8 lg:px-10 bg__theam1 relative">
          <div className="md:w-7/12 lg:w-2/3">
            <div className="mb-0 mb-6 items-center sm:flex md:mb-0">
              <div>
                <h4 className="mb-1 text-xl font-bold text-white xs:text-2xl md:text-xl lg:text-2xl">
                 Tnpsc coaching centre in chennai
                </h4>
                <p className="text-base font-medium text-white">
                  BEST COACHING CENTRE IN CHENNAI
                </p>
              </div>
            </div>
        </div>

          <div className="md:w-5/12 lg:w-1/3 tnpscSC">
            <div className="flex items-center space-x-3 md:justify-end">
             <span className="rounded-lg inline-flex sm:p- md:p-3 bg-purple-50 text-purple-700 ring-4 ring-white">

                    <svg className="md:h-10 md:w-10 h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
                    </svg>
                  </span>
            </div>
          </div>
        </div>
      </div>
      </div>
    <div className="pt-20 pb-60">
      <div className="container__def_coching_bst">
        <GuidanceCategories />
      </div>
    </div>

    <Footer />
  </HashRouter>
);
