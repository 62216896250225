import banner__sub1 from '../Slider01.jpeg';

function Imalogo() {
  return (
    <div> 
    <img className="lazy__width" src={banner__sub1} data-src="image-to-lazy-load-1x.jpg" data-srcset="image-to-lazy-load-2x.jpg 2x, image-to-lazy-load-1x.jpg 1x" />
    </div>       );
}

export default Imalogo;
