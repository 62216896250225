import { Component } from 'react';
import React from 'react';
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';
import { WhatsAppOutlined, InstagramOutlined,TwitterOutlined,YoutubeOutlined,PlusOutlined } from '@ant-design/icons';

export default class footer extends Component {
  render() {
  return (
    <div><footer className="bg-gray-800" aria-labelledby="footer-heading">
  <h2 id="footer-heading" className="sr-only">Footer</h2>
  <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:py-6 lg:px-8">
    <div className="xl:grid xl:grid-cols-3 xl:gap-8">
     <div className="mt-8 xl:mt-0">
        <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Contact Us</h3>
        <p className="mt-4 text-base text-gray-300">
        Our institute is committed to the highest standards of coaching for TNPSC Exams...Read More

        </p>
        <p>No.153/26, Erikkarai Street, GST Road, Urapakkam Chennai 603210.
        </p>
        <p> +91 7358 001 586
        </p>
        <p>
        +91 7358 001 587
        </p>
        <p>
         jsriasacademy@gmail.com
        </p>
        <form className="mt-4 sm:flex sm:max-w-md">
          <label for="email-address" className="sr-only">Email address</label>
          
        </form>
      </div>
      <div className="grid grid-cols-2 gap-8 xl:col-span-2">
        <div className="md:grid md:grid-cols-2 md:gap-8">
          <div>
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Link</h3>
            <ul role="list" className="mt-4 space-y-4">
              <li>
                <Link to="/" className="text-base text-gray-300 hover:text-white"> Home </Link>
              </li>

              <li>
                <Link to="About" href="#" className="text-base text-gray-300 hover:text-white"> About </Link>
              </li>


              <li>
                <Link to="class-schedule" className="text-base text-gray-300 hover:text-white"> Class Schedule </Link>
              </li>
              <li>
                <Link to="materials" className="text-base text-gray-300 hover:text-white"> Materials </Link>
              </li>

              <li>
                <Link to="current-affairs" className="text-base text-gray-300 hover:text-white"> Current Affairs </Link>
              </li>

              <li>
                <Link to="Gallery" className="text-base text-gray-300 hover:text-white"> Gallery</Link>
              </li>
              <li>
                <Link to="Contact-Us" className="text-base text-gray-300 hover:text-white"> Contact Us</Link>
              </li>

            </ul>
          </div>
          <div className="mt-12 md:mt-0">
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">UPSC</h3>
            <ul role="list" className="mt-4 space-y-4">
              <li>
                <Link to="upsc-admission" className="text-base text-gray-300 hover:text-white"> Admission </Link>
              </li>

              <li>
                <Link to="tnpsc-course-calendar" className="text-base text-gray-300 hover:text-white"> Course Calendar</Link>
              </li>
{/*
              <li>
                <Link to="upsc-police" className="text-base text-gray-300 hover:text-white">  police</Link>
              </li>*/}

              <li>
                <Link to="upsc-about-exam" className="text-base text-gray-300 hover:text-white">  About Exam</Link>
              </li>

            </ul>
          </div>
        </div>
        <div className="md:grid md:grid-cols-2 md:gap-8">
          <div>
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">TNPSC</h3>
            <ul role="list" className="mt-4 space-y-4">
              <li>
                <Link to="tnpsc-admission" className="text-base text-gray-300 hover:text-white"> Admission </Link>
              </li>

              <li>
                <Link to="tnpsc-course-calendar" className="text-base text-gray-300 hover:text-white"> Course Calendar </Link>
              </li>
{/*
              <li>
                <Link to="tnpsc-police" className="text-base text-gray-300 hover:text-white"> police </Link>
              </li>*/}

              <li>
                <Link to="tnpsc-about-exam" className="text-base text-gray-300 hover:text-white"> About Exam </Link>
              </li>

            </ul>
          </div>
          <div className="mt-12 md:mt-0">
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Other Course</h3>
            <ul role="list" className="mt-4 space-y-4">
              <li>
                <Link to="police-exam" className="text-base text-gray-300 hover:text-white"> Police Exam </Link>
              </li>

              <li>
                <Link to="SI-exam" className="text-base text-gray-300 hover:text-white"> SI Exam </Link>
              </li>

              <li>
                <Link to="bank-exam" className="text-base text-gray-300 hover:text-white"> Banking Exam </Link>
              </li>
              <li>
                <Link to="SSC-exam" className="text-base text-gray-300 hover:text-white"> SSC Exam </Link>
              </li>
              <li>
                <Link to="RRB-exam" className="text-base text-gray-300 hover:text-white"> RRB Exam </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
     
    </div>
    <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
      <div className="flex space-x-6 md:order-2">
        <a href="https://www.facebook.com/jsr.easttambaram.1" target="_blank" className="text-gray-400 hover:text-gray-300">
          <span className="sr-only">Facebook</span>
          <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd"></path>
          </svg>
        </a>

        <a href="https://www.instagram.com/jsriasacademy/" target="_blank" className="text-gray-400 hover:text-gray-300">
          <span className="sr-only">Instagram</span>
          <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd"></path>
          </svg>
        </a>

        <a href="https://twitter.com/JSR_Academy?s=08" target="_blank" className="text-gray-400 hover:text-gray-300">
          <span className="sr-only">Twitter</span>
          <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
          </svg>
        </a>

        <a href="https://www.youtube.com/channel/UCK3y-sxYw9UzjHJm3l-2_rg" target="_blank" className="text-gray-400 hover:text-gray-300 -mt-2.5">
          <span className="sr-only">YoutubeOutlined</span>
          <YoutubeOutlined className="text-2xl" />
        </a>

        <a href="https://t.me/JSRIASAcademy" target="_blank" className="text-gray-400 hover:text-gray-300">
          <span className="sr-only">YoutubeOutlined</span>
          <Icon icon="ri:telegram-line" className="text-2xl" />
        </a>
      </div>
      <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">© 2023 Webdevelopment, Inc. All rights reserved.</p>
    </div>
  </div>
</footer>
    </div>       );
}

}
