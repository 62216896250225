import React, {Component} from 'react';
import axios from "axios";

class GuidanceCate extends Component {

  render(){

  return (
    <div>
    <div className="container__def_coching_bst flex justify-between flex-wrap">
    <div className="w-full md:w-1/2">
  <h2 className="text-lg font-medium text-gray-900">Interview Guidance</h2>
  <ul role="list" className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
  
    <li>
      <div className="relative group py-3 flex items-start space-x-3">
        <div className="flex-shrink-0">
          <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg bg-pink-500">
            <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"></path>
            </svg>
          </span>
        </div>
        <div className="min-w-0 flex-1">
          <div className="text-sm font-medium text-gray-900">
    
              <span className="absolute inset-0" aria-hidden="true"></span>
              * Free Interview classes.
    
          </div>
        </div>
        <div className="flex-shrink-0 self-center">
        
        </div>
      </div>
    </li>

    <li>
      <div className="relative group py-3 flex items-start space-x-3">
        <div className="flex-shrink-0">
          <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg bg-purple-500">
            
            <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
            </svg>
          </span>
        </div>
        <div className="min-w-0 flex-1">
          <div className="text-sm font-medium text-gray-900">
              <span className="absolute inset-0" aria-hidden="true"></span>
          * Individual attention.
          </div>
          
        </div>
        <div className="flex-shrink-0 self-center">
        
        </div>
      </div>
    </li>

    <li>
      <div className="relative group py-3 flex items-start space-x-3">
        <div className="flex-shrink-0">
          <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg bg-yellow-500">
            
            <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
            </svg>
          </span>
        </div>
        <div className="min-w-0 flex-1">
          <div className="text-sm font-medium text-gray-900">
              <span className="absolute inset-0" aria-hidden="true"></span>
              * Mock Interview by serving bureaucrats and others.
          </div>
        </div>
        <div className="flex-shrink-0 self-center">
        
        </div>
      </div>
    </li>


    <li>
      <div className="relative group py-3 flex items-start space-x-3">
        <div className="flex-shrink-0">
          <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg bg-yellow-500">
            
            <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
            </svg>
          </span>
        </div>
        <div className="min-w-0 flex-1">
          <div className="text-sm font-medium text-gray-900">
              <span className="absolute inset-0" aria-hidden="true"></span>
              * Mock Interview will be recorded and analysis will be shared for further enhancement.
          </div>
        </div>
        <div className="flex-shrink-0 self-center">
        
        </div>
      </div>
    </li>
  </ul>

</div>

<div className="w-full md:w-1/2">
  <h2 className="text-lg font-medium text-gray-900">Course Categories</h2>
  <ul role="list" className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
    <li>
      <div className="relative group py-3 flex items-start space-x-3">
        <div className="flex-shrink-0">
          <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg bg-pink-500">
            <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"></path>
            </svg>
          </span>
        </div>
        <div className="min-w-0 flex-1">
          <div className="text-sm font-medium text-gray-900">
    
              <span className="absolute inset-0" aria-hidden="true"></span>
   * Our institute is committed to the highest standards of coaching for TNPSC Exams .

          </div>
        </div>
        <div className="flex-shrink-0 self-center">
        
        </div>
      </div>
    </li>

    <li>
      <div className="relative group py-3 flex items-start space-x-3">
        <div className="flex-shrink-0">
          <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg bg-purple-500">
            
            <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
            </svg>
          </span>
        </div>
        <div className="min-w-0 flex-1">
          <div className="text-sm font-medium text-gray-900">
              <span className="absolute inset-0" aria-hidden="true"></span>
          * It offers an affordable training program for aspirants.
          </div>
          
        </div>
        <div className="flex-shrink-0 self-center">
        
        </div>
      </div>
    </li>

    <li>
      <div className="relative group py-3 flex items-start space-x-3">
        <div className="flex-shrink-0">
          <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg bg-yellow-500">
            
            <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
            </svg>
          </span>
        </div>
        <div className="min-w-0 flex-1">
          <div className="text-sm font-medium text-gray-900">
              <span className="absolute inset-0" aria-hidden="true"></span>
              The aim is to pursue excellence in coaching by employing talented and well experienced faculty
          </div>
        </div>
        <div className="flex-shrink-0 self-center">
        
        </div>
      </div>
    </li>
  </ul>
  
</div>

</div>

    </div>       );
}
 }
export default GuidanceCate;
